<template>
  <b-card title="">
    <b-row class="justify-content-between d-flex">
      <b-col>
        <b-row class="ml-2">
          <b-form-group>
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar fornecedor"
            ></b-form-input>
          </b-form-group>
        </b-row>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          class="mb-2 mr-2"
          variant="primary"
          @click="modalNewSupplier = true"
        >
          Novo Fornecedor +
        </b-button>
      </b-col>
    </b-row>

    <b-table
      responsive="sm"
      small
      :fields="fields"
      :items="filter()"
      class="text-left"
    >
      <template v-slot:cell(actions)="{ item }">
        <ActionsSuppliers :item="item" @open-delete-modal="openDeleteModal" />
      </template>
    </b-table>
    <ModalFormSuppliers
      @closeModal="closeModal"
      @updateList="handleUpdateList"
      :modalNewSupplier="modalNewSupplier"
    />
    <ModalDeleteSupplier
      v-if="modalDeleteSupplier"
      @closeModal="closeModal"
      @updateList="handleUpdateList"
      :modalDeleteSupplier="modalDeleteSupplier"
      :items="items"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BButton,
  BTable,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue';
import ActionsSuppliers from './components/ActionsSuppliers.vue';
import ModalFormSuppliers from './components/ModalFormSuppliers.vue';
import ModalDeleteSupplier from './components/ModalDeleteSupplier.vue';
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    BCard,
    BRow,
    BButton,
    BTable,
    ActionsSuppliers,
    ModalFormSuppliers,
    ModalDeleteSupplier,
    BFormInput,
    BFormGroup,
    BCol,
  },
  data: () => ({
    modalNewSupplier: false,
    modalDeleteSupplier: false,
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        thStyle: { width: '20%' },
      },
      {
        key: 'supplier_name',
        label: 'Nome',
        sortable: true,
      },
    ],
    items: [],
    search: '',
    taxRecordsList: false,
  }),
  created() {
    this.getAllSuppliers();
  },

  methods: {
    closeModal() {
      this.modalNewSupplier = false;
      this.modalDeleteSupplier = false;
    },
    async getAllSuppliers() {
      this.items = [];
      if (!this.taxRecordsList) {
        await this.$store
          .dispatch('getAllSuppliers', {
            workspace_id: this.$store.getters.currentWorkspace.id,
          })
          .then((resp) => {
            if (resp) {
              this.items = resp;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    filter() {
      if (this.search.trim() === '') {
        // If the search input is empty, return all items
        return this.items;
      } else {
        // If there's a search term, filter the items
        return this.items.filter((item) => {
          return (
            item.supplier_name
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },

    openDeleteModal(item) {
      this.modalDeleteSupplier = true;
      this.$store.modalSupplier = item;
    },

    handleUpdateList() {
      this.getAllSuppliers();
    },
  },
};
</script>
