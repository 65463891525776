<template>
  <b-modal
    title="Novo Fornecedor"
    v-model="modalNewSupplier"
    no-close-on-backdrop
    hide-footer
    hide-header-close
  >
    <validation-observer ref="addSuppliers">
      <b-row class="justify-content-center d-flex">
        <b-form-group class="w-100 p-1">
          <label for="title" class="font-weight-bold text-primary"
            >Nome do Fornecedor:</label
          >
          <validation-provider
            #default="{ errors }"
            :custom-messages="{ required: 'Este campo é obrigatório' }"
            rules="required"
          >
            <b-form-input
              id="title"
              v-model="modelSuppliers.supplier_name"
              placeholder="Digite o nome do Fornecedor"
            ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- taxRate -->
      </b-row>
      <hr class="invoice-spacing" />

      <b-row class="justify-content-between d-flex">
        <!-- back -->
        <b-button
          :disabled="loading"
          variant="warning"
          class="mr-2"
          @click="$emit('closeModal')"
          >Voltar
        </b-button>
        <!-- updateUser -->
        <div class="d-flex justify-content-between">
          <b-button
            v-if="editable"
            variant="primary"
            @click="updateSupplier"
            :disabled="modelSuppliers.supplier_name.length < 1 || loading"
          >
            <span v-if="!loading"> Salvar Alterações </span>
            <b-spinner class="spinner" v-if="loading"></b-spinner>
          </b-button>

          <!-- saveUser -->
          <b-button
            v-if="!editable"
            variant="primary"
            @click="saveSupplier"
            :disabled="modelSuppliers.supplier_name.length < 1 || loading"
          >
            <span v-if="!loading">Salvar</span>
            <b-spinner v-if="loading"></b-spinner>
          </b-button>
        </div>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
export default {
  name: 'new-supplier',
  props: {
    modalNewSupplier: {
      type: Boolean,
      default: false,
    },
    editable: {
      Type: Boolean,
      default: false,
    },
    id: {
      Type: String,
      default: '',
    },
  },
  components: {
    BModal,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      modelSuppliers: {
        supplier_name: '',
        id: '',
      },
      loading: false,
      required,
    };
  },
  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
  },
  created() {
    if (this.editable) {
      this.getSupplier();
    }
  },

  methods: {
    async getSupplier() {
      this.loading = true;

      const id = this.$route.params.id;
      await this.$store
        .dispatch('getSupplier', { id })
        .then((resp) => {
          this.loading = false;
          this.modelSuppliers = resp;
        })
        .catch(() => {
          this.loading = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro buscar usuário',
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    saveSupplier() {
      this.$refs.addSuppliers.validate().then((success) => {
        if (success) {
          this.loading = true;
          const params = {
            workspace_id: this.$store.getters.currentWorkspace.id,
            supplier_name: this.modelSuppliers.supplier_name,
          };

          this.$store
            .dispatch('saveSupplier', params)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Forncedor cadastrado com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('updateList');
                this.$emit('closeModal');
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao cadastrar Forncedor',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },

    updateSupplier() {
      this.$refs.addSuppliers.validate().then((success) => {
        // Correção aqui
        if (success) {
          this.loading = true;
          this.$store
            .dispatch('updateSupplier', this.modelSuppliers)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Usuário atualizada com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$router.push({ name: 'users' });
              }
            })
            .catch((err) => {
              this.loading = false;

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${
                    err.response.data.message ==
                    'this user email is alredy exists'
                      ? 'Email Já cadastrado'
                      : err.response.data.message == 'User not found'
                      ? 'Usuário não encontrada'
                      : 'erro ao atualizar Usuário'
                  }`,
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style></style>
