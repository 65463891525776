<template>
  <b-modal
    title="Excluir Fornecedor"
    v-model="modalDeleteSupplier"
    no-close-on-backdrop
    hide-footer
    hide-header-close
  >
    <validation-observer ref="deleteSupplierModal">
      <b-row class="justify-content-center d-flex">
        <b-form-group class="w-100 p-1">
          <label for="title" class="font-weight-bold text-primary"
            >Deseja mesmo exluir: {{ modelSuppliers.supplier_name }}</label
          >
        </b-form-group>
      </b-row>
      <hr class="invoice-spacing" />

      <b-row class="justify-content-between d-flex">
        <!-- back -->
        <b-button
          :disabled="loading"
          variant="warning"
          class="mr-2"
          @click="$emit('closeModal')"
          >Voltar
        </b-button>

        <!-- back -->
        <b-button
          :disabled="loading"
          variant="danger"
          class="mr-2"
          @click="deleteSupplier"
          >Excluir
        </b-button>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BModal, BRow, BFormGroup, BButton } from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationObserver } from 'vee-validate';
import { required } from '@validations';
export default {
  name: 'new-supplier',
  props: {
    modalDeleteSupplier: {
      type: Boolean,
      default: false,
    },
    excludable: {
      Type: Boolean,
      default: false,
    },
    id: {
      Type: String,
      default: '',
    },
  },
  components: {
    BModal,
    BRow,
    BFormGroup,
    BButton,
    ValidationObserver,
  },
  data() {
    return {
      modelSuppliers: {
        supplier_name: '',
        id: '',
      },
      loading: false,
      required,
    };
  },

  created() {
    this.modelSuppliers = this.$store.supplier;
  },

  methods: {
    deleteSupplier() {
      this.$refs.deleteSupplierModal.validate().then((success) => {
        if (success) {
          this.loading = true;
          const params = {
            workspace_id: this.$store.getters.currentWorkspace.id,
            id: this.modelSuppliers.id,
          };

          this.$store
            .dispatch('deleteSupplier', params)
            .then((resp) => {
              this.loading = false;
              if (resp) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Forncedor excluido com sucesso',
                    icon: 'SuccessIcon',
                    variant: 'success',
                  },
                });
                this.$emit('closeModal');
                this.$emit('updateList');
              }
            })
            .catch(() => {
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao excluir Fornecedor',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style></style>
